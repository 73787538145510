
import { defineComponent, reactive } from 'vue'
import router from '@/router'
import { api } from '@/api/useAxios';
import { getCompanyId } from '@/utils/auth';

interface ImgProps {
  id: string,
  imgUrl: string,
  imgLink?: string
}
// 获取推荐活动
const useRecommendAct = () => {
  const qryParams = {
    "current": 1,
    "size": 4
  }
  let records = reactive<ImgProps[]>([])
  api.post('/product/pageNew', qryParams).then( (resp) => {
    let result = resp.records.map( (item: any) => {
      const img: ImgProps = { id: '', imgUrl: ''}
      img.imgUrl = item.img,
      img.id = item.id
      return img
    });
    records.push(...result)
  })
  return records
}
export default defineComponent({
  name: 'RecommendActivity',
  setup () {
    const records = useRecommendAct()
    const handleDetail = (index: number) => {
      const id = records[index].id
      router.push('/sales/product/' + id)
    }
    const companyId = getCompanyId()
    return {
      records,
      handleDetail,
      toProductList: () => {
        router.push('/sales/product/list')
      },
      companyId
    }
  }

})
