
import { defineComponent, reactive } from 'vue'
import { api } from '@/api/useAxios'
import { useRouter } from 'vue-router'
import { getCompanyId } from '@/utils/auth';

// 获取推荐导师
const useTeachersEffect = () => {
  const teachers = reactive<string[]>([])
  const qryParams = { 
    current: '1', 
    size: '4', 
    teacherType: '', 
    hot: '1' ,
    companyId: getCompanyId()
  }
  api.post('/mmteacher/page', qryParams).then((resp) => {
    const records = resp.records
    teachers.push(...records)
  })
  return teachers
}

export default defineComponent({
  name: 'RecommendTeacher',
  setup () {
    const router = useRouter()
    const teachers = useTeachersEffect()
    return {
      teachers,
      toPage: () => {
        router.push('/findtea')
      },
      toDetail: (e: any) => {
        router.push({
          path: '/teacher/detail/'+e.userId
        })
      }
    }
  }
})
