
import { defineComponent, reactive, ref } from 'vue'
import CourseType from './components/CourseType.vue'
import RecommendTeacher from './components/RecommendTeacher.vue'
import RecommendCourse from './components/RecommendCourse.vue'
import RecommendActivity from './components/RecommendActivity.vue'
import { api } from '@/api/useAxios';
import { changeShareInfo } from '@/utils/wechat'
import { getCompanyId } from '@/utils/auth';

interface ImgProps {
  id: string,
  imgUrl: string,
  imgLink: string
}
export default defineComponent({
  name: 'Home',
  components: {
    CourseType,
    RecommendTeacher,
    RecommendCourse,
    RecommendActivity
  },
  setup () {
    let imgUrls = reactive<ImgProps[]>([])
    api.post('/companybanner/list',{companyId: getCompanyId()}).then( resp =>{
      const result = resp.map( (item: any) => {
        const img:ImgProps = { id: '', imgUrl: '', imgLink: ''}
        img.id = item.id
        img.imgUrl = item.url
        img.imgLink = item.linkUrl
        return img
      })
      imgUrls.push(...result)
    })
    const show = ref(false)
    api.get('/sysuser/checkuserisfocus').then( r =>{
      if(!r){
        show.value = true
      }
    })

    const handleDetail = (index: number) => {
      const linkUrl: string = imgUrls[index].imgLink
      if (linkUrl) window.location.href = linkUrl
    }
    changeShareInfo({
      title: process.env.VUE_APP_PAGE_TITLE,
      desc: process.env.VUE_APP_PAGE_DESC,
      link: window.location.href,
      imgUrl: process.env.VUE_APP_SHARE_IMG
    })
    const qrImgUrl = process.env.VUE_APP_WECHAT_QRCODE
    return {
      show,
      imgUrls,
      qrImgUrl,
      handleDetail
    }
  }
})
