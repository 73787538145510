
import { defineComponent, reactive } from 'vue'
import { api } from '@/api/useAxios'
import { useRouter } from 'vue-router'

// 获取课程类型
const useCourseTypeEffect = () => {
  const courseTags = reactive<string[]>([])
  api.get('/tag/listAllByType/2').then((resp: string[]) => {
    courseTags.push(...resp)
  })
  return courseTags
}

export default defineComponent({
  name: 'CourseType',
  setup () {
    const router = useRouter()
    const courseTags = useCourseTypeEffect()
    return {
      courseTags,
      goToPage: (id: number) => {
        router.push({
          path: '/course-series-list',
          query: {
            id: id
          }
        })
      }
    }
  }
})
