
import { defineComponent, reactive, Ref, ref, toRefs, watchEffect } from 'vue'
import { api } from '@/api/useAxios';
import { RecommendCourse, createRecommendCourse } from 'momai'
import { useRouter } from 'vue-router'
import { getCompanyId } from '@/utils/auth';

const courses = reactive({
  records : [],
  pages: 0
})

// 获取推荐课程
const useRecommendCoursesEffect = ( page: Ref) => {
  const qryParams = {
    current: page.value,
    size: 6,
    companyId: getCompanyId()
  }
  const getCoursesData = async () => {
    await api.post('/course/pageNew', qryParams).then( (resp) => {
      const result = resp.records.map( (item: any) => {
        // rsrv2
        const recommendCourse: RecommendCourse= createRecommendCourse()
        recommendCourse.courseId = item.id
        recommendCourse.img = item.img
        recommendCourse.courseName = item.courseName
        recommendCourse.studyNum = item.rsrv2
        return recommendCourse
      });
      courses.records = result
      courses.pages = resp.pages
    })
  }
  watchEffect( () => { getCoursesData() })
  const { records, pages } = toRefs(courses)
  return { records, pages }
}

export default defineComponent({
  name: 'RecommendCourse',
  setup () {
    let pageNo = ref(1)
    const { records, pages } = useRecommendCoursesEffect(pageNo)
    const handlePageClick = () => {
      pageNo.value += 1
      if (pageNo.value > pages.value) pageNo.value = 1
      useRecommendCoursesEffect(pageNo)
    }
    const router = useRouter()
    const toCourseList = () => {
      router.push('/course-series-list')
    }
    return {
      records,
      handlePageClick,
      toCourseList,
      toCourseDetail: (id:string) =>{
        router.push('/coursedetail/'+id)
      }
    }
  }
})
